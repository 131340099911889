import React from "react";
import HSCService from "../sections/standardPage/HSCService";
import { graphql } from 'gatsby'

const HSCPage = (data) =>
    <HSCService data={data}/>

export default HSCPage;

export const query = graphql`
    {
        contentfulStandardPage(pageId: {eq: 2}) {
            heading
            subheading
            pageName
            pageId
            bodyText {
                json
            }
            twoColumns
            secondColumnBodyText {
                json
            }
            pageImage {
                fluid(maxWidth: 1180) {
                    ...GatsbyContentfulFluid
                }
            }
        }
    }`
